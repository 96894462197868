body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  color: #333;
  background-color: #f4f4f4;
  animation: fadeIn 1s ease-in;
}

header {
  background-color: #333;
  color: #fff;
  padding: 4px 0;
  animation: slideInDown 1s ease-out;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;
}

.container {
  width: 80%;
  margin: 0 auto;
  max-width: 1200px;
}

.logo-and-text {
  display: flex;
  align-items: center;
}

.logo img {
  width: 50px;
  height: auto;
}

nav {
  float: right;
}

nav ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

nav ul li {
  display: inline;
  margin-left: 20px;
}

nav ul li a {
  color: #fff;
  text-decoration: none;
}

.hero {
  color: #fff;
  padding: 100px 0;
  text-align: center;
  animation: fadeIn 2s ease-in;
}

.hero h1 {
  font-size: 48px;
  margin-bottom: 10px;
}

.hero p {
  font-size: 24px;
  margin-bottom: 20px;
}

.btn {
  background-color: #e8491d;
  color: #fff;
  padding: 10px 20px;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.btn:hover {
  background-color: #cf3e17;
}

section {
  padding: 60px 0;
  animation: fadeInUp 1s ease-in-out;
}

h2 {
  text-align: center;
  margin-bottom: 20px;
  animation: fadeIn 1.5s ease-in;
}

.service-list {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.service-item {
  background-color: #fff;
  padding: 20px;
  margin: 10px;
  box-shadow: 0 0 10px rgba(0,0,0,0.1);
  flex: 1 1 calc(33% - 40px);
  box-sizing: border-box;
  animation: zoomIn 1s ease-in-out;
}

.service-item h3 {
  margin-top: 0;
}

form {
  display: flex;
  flex-direction: column;
}

form label {
  margin-bottom: 5px;
}

form input, form textarea {
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  animation: fadeIn 1.5s ease-in;
}

form button {
  background-color: #e8491d;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  animation: fadeIn 2s ease-in;
}

footer {
  background-color: #333;
  color: #fff;
  text-align: center;
  padding: 20px 0;
  animation: slideInUp 1s ease-out;
}

/* Animations */
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes slideInDown {
  from { transform: translateY(-100%); }
  to { transform: translateY(0); }
}

@keyframes fadeInUp {
  from { opacity: 0; transform: translateY(20px); }
  to { opacity: 1; transform: translateY(0); }
}

@keyframes zoomIn {
  from { transform: scale(0.9); }
  to { transform: scale(1); }
}

@keyframes slideInUp {
  from { transform: translateY(100%); }
  to { transform: translateY(0); }
}

.blue-shadow {
  box-shadow: 0 4px 20px rgba(0, 0, 255, 0.5);
}

.a4-size {
  width: 210mm;
  min-height: 297mm;
  padding: 16mm;
  box-sizing: border-box;
  overflow: hidden;
}

@media (max-width: 210mm) {
  .a4-size {
    width: 100%;
    min-height: auto;
    padding: 16px;
  }
}